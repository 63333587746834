import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

//middleware
import Middlewares from '../middlewares'
//end

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "forgot password" */ '../views/Auth/Forgot-Password.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "reset password" */ '../views/Auth/Reset-Password.vue')
  },
  {
    path: '/resend-email',
    name: 'resend-email',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "resend email" */ '../views/Auth/Resend-Email.vue')
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "verify password" */ '../views/Auth/Verify-Email.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Dashboard',
        name: 'dashboard'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/dashboard.vue')
  },
  {
    path: '/user',
    name: 'user-profile',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'User',
        name: 'user-profile'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/user-profile.vue')
  },
  {
    path: '/user-status',
    name: 'user-status',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Status',
        parent: 'user-profile'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/user-status.vue')
  },
  {
    path: '/kelas',
    name: 'kelas',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas',
        name: 'kelas'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/kelas.vue')
  },
  {
    path: '/formulir/surat-tugas',
    name: 'surat-tugas',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kirim Surat Tugas',
        parent: 'history-formulir'
      }
    },

    component: () => import(/* webpackChunkName: "surat tugas" */ '../views/formulir/surat_tugas.vue')
  },
  {
    path: '/formulir/surat-keterangan',
    name: 'surat-keterangan',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kirim Surat Keterangan',
        parent: 'history-formulir'
      }
    },

    component: () => import(/* webpackChunkName: "surat keterangan" */ '../views/formulir/surat_keterangan.vue')
  },
  {
    path: '/formulir/history-formulir',
    name: 'history-formulir',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Formulir',
        name: 'history-formulir'
      }
    },

    component: () => import(/* webpackChunkName: "history formulir" */ '../views/formulir/history_formulir.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function recursiveNext(context, middleware, index) {

  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters); //hooks

    const next = recursiveNext(context, middleware, index + 1);

    nextMiddleware({ ...context, next: next });
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {

    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    }

    const nextMiddleware = recursiveNext({ from: from, next: next, router: router, to: to }, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next
})

export default router
