var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"main-sidebar"},[_c('section',{staticClass:"sidebar"},[_c('div',{staticClass:"user-panel mb-5"},[_vm._m(0),(_vm.information)?_c('div',{staticClass:"pull-left info"},[_c('p',[_vm._v(_vm._s(_vm.information.nama))]),_vm._m(1)]):_c('div',{staticClass:"pull-left info"},[_c('p',[_vm._v("loading...")]),_vm._m(2)])]),_c('ul',{staticClass:"sidebar-menu",attrs:{"data-widget":"tree"}},[_c('li',{staticClass:"header"},[_vm._v("MAIN NAVIGATION")]),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_c('i',{staticClass:"fa fa-book"}),_vm._v(" "),_c('span',[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"treeview"},[_vm._m(3),_c('ul',{staticClass:"treeview-menu"},[_c('li',[_c('router-link',{attrs:{"to":"/user"}},[_c('i',{staticClass:"fa fa-circle-o"}),_c('span',[_vm._v(" User Profile")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/user-status"}},[_c('i',{staticClass:"fa fa-circle-o"}),_vm._v(" "),_c('span',[_vm._v("Status")])])],1)])]),_c('li',[_c('router-link',{attrs:{"to":"/kelas"}},[_c('i',{staticClass:"fa fa-book"}),_vm._v(" "),_c('span',[_vm._v("Kelas")])])],1),_c('li',{staticClass:"header"},[_vm._v("Formulir")]),_c('li',{staticClass:"treeview"},[_vm._m(4),_c('ul',{staticClass:"treeview-menu"},[_c('li',[_c('router-link',{attrs:{"to":"/formulir/surat-tugas"}},[_c('i',{staticClass:"fa fa-circle-o"}),_vm._v(" "),_c('span',[_vm._v("Surat Tugas")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/formulir/surat-keterangan"}},[_c('i',{staticClass:"fa fa-circle-o"}),_vm._v(" "),_c('span',[_vm._v("Surat Keterangan")])])],1)])]),_c('li',{staticClass:"header"},[_vm._v("Terms and Conditions")]),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pull-left image"},[_c('img',{staticClass:"img-circle",attrs:{"src":"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png","alt":"User Image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-circle text-success"}),_vm._v(" Online")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-circle text-success"}),_vm._v(" Online")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-dashboard"}),_vm._v(" "),_c('span',[_vm._v("User")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":""}},[_c('i',{staticClass:"fa fa-share"}),_vm._v(" "),_c('span',[_vm._v("Pengiriman Formulir")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-book"}),_vm._v(" "),_c('span',[_vm._v("Terms and Conditions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://rsudbandungkiwari.or.id/hubungi-kami/"}},[_c('i',{staticClass:"fa fa-book"}),_vm._v(" "),_c('span',[_vm._v("Hubungi Kami")])])])
}]

export { render, staticRenderFns }