import axios from "../../../helpers/axios.js";
import store from "../..";

export default {
    /*
    *------------------------------
    * Proses Indetifikasi Ability
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */

    Abilities: async (context) => {
        const ability = await axios({
            method: 'GET',
            url: 'abilities',
        })

        context.commit('SetAbilities', ability.data.datas);

        return ability
    },

    Access: async (context, payload) => {
        const access = await axios({
            method: 'GET',
            url: 'access'
        })

        context.commit('SetAccess', access.data.datas)
        return access.data.data
    },

    /*
    *------------------------------
    * Proses Auth
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */

    handleRegister: async (context, payload) => {
        const register = await axios({
            method: 'POST',
            url: 'register',
            data: payload
        })

        return register
    },
    handleLogin: async (context, payload) => {
        const login = await axios({
            method: 'POST',
            url: 'login',
            data: payload
        })

        localStorage.setItem('access_token', login.data.access_token)
        await store.dispatch('portal/Abilities')
        await store.dispatch('portal/Access')

        return login
    },
    handleLogout: async (context) => {
        const logout = await axios({
            method: 'GET',
            url: 'logout',
        })

        await store.dispatch('portal/deleteSessions')

        return logout
    },
    deleteSessions: async (context, payload) => {
        if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            context.commit('SetAbilities', { information: { email: '', nama: '', roles: '' } });
            context.commit('SetLoggedIn', false);
            return true
        } else {
            context.commit('SetLoggedIn', false);
            return false
        }

    },
    setLoggedInState: async (context) => {
        if (localStorage.getItem('access_token')) {
            context.commit('SetLoggedIn', true);
            return true
        } else {
            context.commit('SetLoggedIn', false);
            return false
        }
    },
    emailChecking: async (context, payload) => {
        const check = await axios({
            method: 'POST',
            url: 'email-checking',
            data: payload
        })

        return check.data
    },
    setRegister: async (context, payload) => {
        console.log(payload.step);
        context.commit('SetRegister', payload);
    },
    forgotPassword: async (context, payload) => {
        const forgotPassword = await axios({
            method: 'POST',
            data: payload,
            url: 'forgot-password',
        })

        return forgotPassword.data
    },
    resetPassword: async (context, payload) => {
        const resetPassword = await axios({
            method: 'POST',
            data: payload,
            url: 'reset-password',
        })

        return resetPassword.data
    },
    resendEmail: async (context, payload) => {
        const resendEmail = await axios({
            method: 'POST',
            data: payload,
            url: 'email-verification/resend',
        })

        return resendEmail.data
    },
    verifEmail: async (context, payload) => {
        const verifEmail = await axios({
            method: 'POST',
            data: payload,
            url: 'email-verification',
        })

        return verifEmail.data
    },

    //dashboard

    getKelasRN: async (context, payload) => {
        const kelas = await axios({
            method: 'GET',
            url: 'portal/kelas-sekarang'
        })

        return kelas.data
    },

    getEvent: async (context, payload) => {
        const event = await axios({
            method: 'GET',
            url: 'portal/event'
        })

        return event.data
    },

    getProfile: async (context, payload) => {
        const kelas = await axios({
            method: 'GET',
            url: 'portal/get-profile'
        })

        return kelas.data
    },

    getKelas: async (context, payload) => {
        const kelas = await axios({
            method: 'GET',
            url: 'portal/kelas'
        })

        return kelas.data
    },

    //formulir

    getHistoryFormulir: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: 'portal/formulir?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&t=' + payload.jenis_surat +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField,
        })

        return formulir.data
    },

    //user

    changeProfile: async (context, payload) => {
        const profile = await axios({
            method: 'POST',
            data: payload,
            url: 'update-profile'
        })

        return profile.data
    },

    changePassword: async (context, payload) => {
        const avatar = await axios({
            method: 'POST',
            data: payload,
            url: 'change-password'
        })

        return avatar.data
    },

}