<template>
  <header class="main-header">
    <!-- Site wrapper -->
    <!-- Logo -->
    <a href="./adminlte-2.4.18/index2.html" class="logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="logo-mini"><b>BK</b></span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg"><b>PORTAL</b> RSUD BK</span>
    </a>
    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top">
      <!-- Sidebar toggle button-->
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </a>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <!-- User Account: style can be found in dropdown.less -->
          <li class="dropdown user user-menu">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              v-if="information"
            >
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                class="user-image"
                alt="User Image"
              />
              <span class="hidden-xs" v-show="information">
                {{ information.nama }}
              </span>
            </a>
            <a v-else>loading...</a>
            <ul class="dropdown-menu">
              <!-- User image -->
              <li class="user-header" v-if="information">
                <img
                  src="https://cdn.vuetifyjs.com/images/lists/1.jpg"
                  class="img-circle"
                  alt="User Image"
                />

                <p>
                  {{ information.nama }} - {{ information.roles }}
                  <small>Member since Nov. 2012</small>
                </p>
              </li>
              <!-- Menu Footer-->
              <li class="user-footer">
                <div class="pull-left">
                  <a href="#" class="btn btn-default btn-flat">Profile</a>
                </div>
                <div class="pull-right">
                  <a
                    href="#"
                    class="btn btn-default btn-flat"
                    @click.prevent="handleLogout"
                    >Sign out</a
                  >
                </div>
              </li>
            </ul>
          </li>
          <!-- Control Sidebar Toggle Button -->
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions } from "vuex"

export default {
  props: ["information"],
  data() {
    return {
      isLogin: false,
    }
  },
  methods: {
    ...mapActions({
      logout: "portal/handleLogout",
    }),
    async handleLogout() {
      try {
        const logout = await this.logout()

        this.$router.push({
          name: "login",
        })

        this.load = true
      } catch (error) {
        console.log(error.response)
      }
    },
  },
}
</script>
