import Vue from 'vue'
import Vuex from 'vuex'
import portal from './modules/portal'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: {
      name: 'Portal Bandung Kiwari'
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    portal,
  }
})
