import store from "../store"
export default async function auth({ next, router }) {

    const userPermissions = store.getters['portal/Abilities'].permissions;
    const body = document.body.classList

    try {
        if (!userPermissions) {
            // const healtcheck = await store.dispatch('portal/Healt-Check')
            const ability = await store.dispatch('portal/Abilities')
            const access = await store.dispatch('portal/Access')
        }

        body.remove('login-page')

        return next();
    } catch (error) {
        if (error.response.status === 401) {
            await store.dispatch('portal/deleteSessions')
            return router.push({ name: 'login' });
        }
    }

}