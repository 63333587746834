<template>
  <footer class="main-footer">
    <div class="pull-right hidden-xs"><b>Version</b> 2.4.18</div>
    <strong>Copyright &copy; RSUD BK.</strong>
    All rights reserved.
  </footer>
</template>

<script>
export default {}
</script>

<style></style>
