import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import VueSweetalert2 from 'vue-sweetalert2';
import VueToast from 'vue-toast-notification';

/* CSS IMPORT */
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';
/* END CSS IMPORT */

Vue.config.productionTip = false

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VueBreadcrumbs);
Vue.use(VueSweetalert2);
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
