<template>
  <div id="app">
    <div class="wrapper" v-if="loggedIn">
      <!-- Top side column. contains the navbar -->
      <NavBar :information="Abilities.information" />
      <!-- =============================================== -->

      <!-- Left side column. contains the sidebar -->
      <SideBar :information="Abilities.information" />
      <!-- =============================================== -->

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->

        <!-- Content Header (Page header) -->

        <section class="content-header">
          <h1 v-if="this.$route.meta.breadcrumb != null">
            {{ this.$route.meta.breadcrumb.label }}
          </h1>

          <ol class="breadcrumb">
            <router-link
              tag="li"
              v-for="(crumbs, index) in $breadcrumbs"
              :index="index"
              :key="index"
              :to="getPath(crumbs)"
            >
              <i
                class="fa fa-dashboard"
                v-show="index == 0"
                style="margin: 0px 3px"
              />

              {{ getBreadcrumb(crumbs) }}
              <!-- {{ crumbs.meta.breadcrumb.label }} -->
            </router-link>
          </ol>
        </section>

        <!-- Main content -->
        <section class="content">
          <router-view />
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->

      <!-- Bottom side column. contains the footer -->
      <Footer />
      <!-- =============================================== -->
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import NavBar from "./components/navbar.vue"
import SideBar from "./components/sidebar.vue"
import Footer from "./components/footer.vue"
export default {
  components: {
    NavBar,
    SideBar,
    Footer,
  },
  async created() {
    try {
      const checkLogin = await this.checkUserState()

      if (checkLogin) {
        this.isLogin = true
      }

      this.load = true
    } catch (err) {
      if (err.response.status === 401) {
        this.$router.push({
          name: "login",
        })

        this.load = true
      }
    }
  },
  data() {
    return {
      load: false,
      drawer: false,
      isLogin: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: "portal/LoggedIn",
      Abilities: "portal/Abilities",
    }),
  },
  methods: {
    ...mapActions({
      checkUserState: "portal/setLoggedInState",
      ability: "portal/Abilities",
      deleteSessions: "portal/deleteSessions",
      logout: "portal/handleLogout",
    }),
    async handleLogout() {
      const logout = await this.logout()

      this.load = true
    },
    getBreadcrumb(crumbs) {
      return crumbs.meta.breadcrumb.label
    },
    getPath(crumbs) {
      return { name: crumbs.name }
    },
    async handleLogout() {
      const logout = await this.logout()

      this.load = true
    },
  },
}
</script>

<style>
ol li {
  cursor: pointer;
  color: #000;
}

ol li.router-link-active {
  cursor: default;
}

.swal-custom {
  font-size: 1.6rem !important;
  font-family: Georgia, serif;
}
.swal-custom2 {
  font-size: 1.4rem !important;
  font-family: Georgia, serif;
}

/* loading */
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 15px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
